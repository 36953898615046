import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { router } from '@inertiajs/react';
import SegmentEvents from './analytics';
import { SharedPageProps } from './components/common/types';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { AnalyticsBrowser } from '@segment/analytics-next';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/(api.|api-dev.)?meetmae\.com(\/api)?\/\S*$/,
  ],
  // only do non error replays in production
  replaysSessionSampleRate:
    import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'production' ? 0 : 0.1,
  // don't do error replays locally
  replaysOnErrorSampleRate:
    import.meta.env.VITE_SENTRY_ENVIRONMENT === 'local' ? 0 : 1.0,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

if (import.meta.env.VITE_MIXPANEL_ENABLED) {
  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    record_sessions_percent: 10,
  });
}

export const analytics = new AnalyticsBrowser();
analytics.load(
  {
    writeKey: import.meta.env.VITE_SEGMENT_TOKEN,
    cdnURL: 'https://cdnsegment.meetmae.com',
  },
  { integrations: { 'Segment.io': { apiHost: 'apisegment.meetmae.com/v1' } } },
);

if (import.meta.env.VITE_MIXPANEL_ENABLED) {
  analytics.addSourceMiddleware(({ payload, next }) => {
    const userId = payload.obj.userId;
    const segmentDeviceId = payload.obj.anonymousId;
    if (payload.obj.type === 'track' || payload.obj.type === 'page') {
      if (window.mixpanel) {
        mixpanel.register({
          $device_id: segmentDeviceId,
          distinct_id: userId ?? segmentDeviceId,
        });
        const sessionReplayProperties =
          mixpanel.get_session_recording_properties();
        payload.obj.properties = {
          ...payload.obj.properties,
          ...sessionReplayProperties,
        };
      }
    }
    next(payload);
  });
}

createInertiaApp({
  progress: {
    color: '#DB2777',
    includeCSS: false,
  },
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.tsx`,
      import.meta.glob('./Pages/**/*.tsx'),
    ),
  setup({ el, App, props }) {
    createRoot(el).render(
      <Sentry.ErrorBoundary>
        <App {...props} />
      </Sentry.ErrorBoundary>,
    );
  },
});

// TODO: bring this back (see ticket EN-1319)
// if (import.meta.env.NODE_ENV !== 'production') {
//   import('@axe-core/react').then((axe) => {
//     axe.default(React, ReactDOM, 1000);
//   });
// }

router.on('navigate', (event) => {
  const { user } = (event.detail.page.props as unknown as SharedPageProps).auth;
  try {
    if (user === null) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (window as any).ap3c.clearSession();
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (window as any).ap3c.talk.execute('hide');
    } else {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (window as any).ap3c?.track({
        v: 0,
        email: user.data.email,
      });
    }
  } catch {
    console.log('ortto not loaded');
  }
  Sentry.setUser(user ? { id: `${user.type}-${user.data.id}` } : null);
  SegmentEvents.identify(user);
  SegmentEvents.page();
});

// need to set includeCSS: false for now while CSP support
// is missing: https://github.com/inertiajs/progress/issues/21
