/// <reference types="@types/segment-analytics" />

import { LoggedInUser } from './components/common/types';
import { ContentType, PregnancyWeek } from 'shared-types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { analytics } from './app';

const weekString = (week: PregnancyWeek) => week.type + week.weekNumber;

const page = () => analytics.page();

const noop = () => {};

const logout = () => {
  analytics.track('User Logged Out');
  analytics.reset();
};

const identify = (user: LoggedInUser | null) => {
  if (user === null) {
    analytics.identify();
  } else {
    switch (user.type) {
      case 'user': {
        analytics.identify(`${user.type}-${user.data.id}`, {
          email: user.data.email,
          maeId: user.data.id,
          phone: user.data.phone,
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          createdAt: user.data.createdAt.timestamp,
          onboarded: user.data.onboarded,
          state: user.data.state,
          type: user.type,
          insuranceName: user.data.userInsurance?.insuranceProviderName,
          hasMaePartnerInsurance: user.data.hasMaePartnerInsurance,
          insuranceVerified: user.data.insuranceVerified,
          insuranceEligible: user.data.insuranceEligible,
          npsScore: user.data.npsScore,
          dueDate: user.data.dueDate
            ? DateTime.fromISO(user.data.dueDate).toFormat('LL/dd/yyyy')
            : null,
          childBirthDate: user.data.childBirthDate
            ? DateTime.fromISO(user.data.childBirthDate).toFormat('LL/dd/yyyy')
            : null,
          deactivatedAt: user.data.deactivatedAt?.timestamp
            ? DateTime.fromISO(user.data.deactivatedAt?.timestamp).toFormat(
                'LL/dd/yyyy',
              )
            : null,
        });
        break;
      }
      case 'doula': {
        analytics.identify(`$doula-${user.data.id}`, {
          email: user.data.email,
          maeId: user.data.id,
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          createdAt: user.data.createdAt.timestamp,
          onboarded: user.data.onboarded,
          state: user.data.state,
          type: user.type,
          doulaInsurances: user.data.insuranceDoulas.map(
            (insuranceDoula) => insuranceDoula.insuranceProvider.name,
          ),
          paymentsEnabled: user.data.paymentsEnabled,
          approved: user.data.status === 1 ? true : false,
          phone: user.data.phone,
        });
        break;
      }
    }
  }
};

const identifyWithUserIdOnly = (userId: string) => {
  analytics.identify(`user-${userId}`);
};

const trackBirthPlanEmailSubmitted = () => analytics.track('Lead');

const startWeeklyQuiz = (week: PregnancyWeek) => {
  analytics.track('Weekly Quiz Started', {
    week: weekString(week),
  });
};

const completedWeeklyQuiz = (week: PregnancyWeek) => {
  analytics.track('Weekly Quiz Completed', {
    week: weekString(week),
  });
};

const clickedOnLifestyleImage = (week: PregnancyWeek) => {
  analytics.track('Lifestyle Image Clicked', { week: weekString(week) });
};

const lifestyleImageModalDismissed = (week: PregnancyWeek) => {
  analytics.track('Lifestyle Image Modal Dismissed', {
    week: weekString(week),
  });
};

const clickedOnRecommendation = (info: {
  type: ContentType;
  id: string;
  title?: string;
}) => {
  const { id, type, title } = info;
  analytics.track('Recommendation Card Clicked', {
    type,
    id,
    title,
  });
};

const recommendationModalDismissed = (info: {
  type: ContentType;
  id: string;
  title?: string;
}) => {
  const { id, type, title } = info;
  analytics.track('Recommendation Modal Dismissed', {
    type,
    id,
    title,
  });
};

const userSignUpButtonClicked = (withErrors: boolean) => {
  analytics.track('User Sign Up Button Clicked', { withErrors });
};

const userSignedUp = () => {
  analytics.track('User Signed Up');
};

const doulaSignUpButtonClicked = (withErrors: boolean) => {
  analytics.track('Doula Sign Up Button Clicked', { withErrors });
};

const expertSearchStateChanged = (state: string) => {
  analytics.track('Expert Search State Changed', { state });
};

const birthPlanCreated = () => {
  analytics.track('Birth Plan Created');
};

const birthPlanUpdated = () => {
  analytics.track('Birth Plan Updated');
};

const birthPlanEditButtonClicked = () => {
  analytics.track('Birth Plan Edit Button Clicked');
};

const onboardingStepViewed = (onboardingStep: string) => {
  analytics.track('Onboarding Step Viewed', {
    onboardingStep,
  });
};

const contentLibrarySearched = (
  text: string | null,
  filterUsed: ContentType | null,
  numberOfResults: number,
) => {
  analytics.track('Content Library Searched', {
    text,
    filterUsed,
    numberOfResults,
  });
};

const doulaListSearched = (
  location: string | null,
  numberOfResults: number,
) => {
  analytics.track('Doula List Searched', {
    location,
    numberOfResults,
  });
};

const profileUpdated = (dueDateChanged: boolean) => {
  analytics.track('Profile Updated', { dueDateChanged });
};

const passwordUpdated = () => {
  analytics.track('Password Updated');
};

const emailUpdated = () => {
  analytics.track('Email Updated');
};

const inHouseConsultRequested = (rescheduleLink: string, ccName: string) => {
  analytics.track('In House Consult Requested', {
    rescheduleLink,
    ccName,
  });
};

const consultFormCompleted = () => {
  analytics.track('Consult Form Completed');
};

const sessionRequestedByMember = (
  doulaId: string,
  sessionId: string,
  sessionType: 'prenatal' | 'birth' | 'postpartum',
) => {
  analytics.track('Session Requested By Member', {
    doulaId,
    sessionId,
    sessionType,
  });
};

const sessionScheduledByDoula = (
  doulaId: string,
  sessionId: string,
  sessionType: 'prenatal' | 'birth' | 'postpartum',
) => {
  analytics.track('Session Scheduled By Doula', {
    doulaId,
    sessionId,
    sessionType,
  });
};

const checkInSubmitted = (userId: string) => {
  analytics.track('Check In Completed', { userId });
};

const npsScoreModalOpened = () => {
  analytics.track('NPS Score Modal Opened');
};

const npsScoreAnswerSelected = (answer: 'yes' | 'no') => {
  analytics.track('NPS Score Answer Selected', { answer });
};

const npsScoreRatingSelected = (rating: number) => {
  analytics.track('NPS Score Rating Selected', { rating });
};

const npsScoreRatingSubmitted = (rating: number) => {
  analytics.track('NPS Score Rating Submitted', { rating });
};

const npsScoreNextButtonPressed = () => {
  analytics.track('NPS Score Next Button Pressed');
};

const npsScoreCancelButtonPressed = () => {
  analytics.track('NPS Score Cancel Button Pressed');
};

const npsScoreTellUsMoreSubmitButtonPressed = () => {
  analytics.track('NPS Score Tell Us More Submit Button Pressed');
};

const npsScoreTellUsMoreCancelButtonPressed = () => {
  analytics.track('NPS Score Tell Us More Cancel Button Pressed');
};

const sessionCancelModalOpened = (
  sessionId: string,
  sessionType: 'prenatal' | 'birth' | 'postpartum',
) => {
  analytics.track('Session Cancel Modal Opened', { sessionId, sessionType });
};

const sessionCancelModalDismissed = (
  sessionId: string,
  sessionType: 'prenatal' | 'birth' | 'postpartum',
) => {
  analytics.track('Session Cancel Modal Dismissed', {
    sessionId,
    sessionType,
  });
};

const sessionCanceled = (
  sessionId: string,
  sessionType: 'prenatal' | 'birth' | 'postpartum',
) => {
  analytics.track('Session Canceled', { sessionId, sessionType });
};

const checklistViewed = (userId: string) => {
  analytics.track('Checklist Viewed', { userId });
};

const checklistActionCompleted = (
  userId: string,
  action: 'weekly-quiz' | 'consult' | 'birth plan' | 'mamaClass',
) => {
  analytics.track('Checklist Action Completed', { userId, action });
};

const allChecklistCompleted = (userId: string) => {
  analytics.track('All 3 Checklist Actions Completed', { userId });
};

const mamaClassInterestedButtonClicked = (title: string) => {
  analytics.track('Mama Class Interested Button Clicked', { title });
};

const mamaClassSuggestionButtonClicked = (title: string) => {
  analytics.track('Mama Class Suggestion Button Clicked', { title });
};

const mamaClassWaitlistButtonClicked = (title: string, classId: string) => {
  analytics.track('Mama Class Waitlist Button Clicked', { title, classId });
};

const mamaClassSignUpButtonClicked = (title: string, classId: string) => {
  analytics.track('Mama Class Sign Up Button Clicked', { title, classId });
};

const mamaClassRegisterButtonClicked = (title: string, classId: string) => {
  analytics.track('Mama Class Register Button Clicked', { title, classId });
};

const previousDoulaSubmitted = () => {
  analytics.track('User Already Working With Doula');
};

const doulaSwitchedClientOrder = (switchedTo: 'default' | 'due date') => {
  analytics.track('Doula Switched Client List Order', { switchedTo });
};

const educationalMaterialsShareButtonClicked = (title: string | 'no title') => {
  analytics.track('Educational Materials Share Button Clicked', { title });
};

const mamaClassShareButtonClicked = (title: string | 'no title') => {
  analytics.track('Mama Class Share Button Clicked', { title });
};

const shareButtonClicked = (
  title: string | 'no title',
  type: 'mama-class' | 'content-library',
) => {
  if (type === 'content-library') {
    educationalMaterialsShareButtonClicked(title);
  } else if (type === 'mama-class') {
    mamaClassShareButtonClicked(title);
  }
};

const phoneConsultClicked = () => {
  analytics.track('Phone Consult Button Clicked');
};

const consultFormClicked = () => {
  analytics.track('Consult Form Button Clicked');
};

const alreadyWorkingWithDoulaClicked = () => {
  analytics.track('Already Working with a Mae Doula Button Clicked');
};

const skipConsultClicked = () => {
  analytics.track('Skip Consult Button Clicked');
};

const onDemandVideoInteraction = (
  type: 'started' | 'finished',
  title: string,
  classId: string,
) => {
  switch (type) {
    case 'started':
      analytics.track('Started On-Demand Video', { title, classId });
      break;
    case 'finished':
      analytics.track('Finished On-Demand Video', { title, classId });
      break;
    default:
      throw new Error('unrecognized analytic event');
  }
};

const mamaClassWatchButtonClicked = (title: string, classId: string) => {
  analytics.track('Clicked Watch Video Button', { title, classId });
};

const programOverviewVideoWatched = (
  type: 'started' | 'finished',
  title: string,
) => {
  switch (type) {
    case 'started':
      analytics.track('Started Program Overview Video', { title });
      break;
    case 'finished':
      analytics.track('Finished Program Overview Video', { title });
      break;
    default:
      throw new Error('unrecognized analytic event');
  }
};

const whatToExpectVideoWatched = (
  type: 'started' | 'finished',
  title: string,
) => {
  switch (type) {
    case 'started':
      analytics.track('Started What To Expect Video', { title });
      break;
    case 'finished':
      analytics.track('Finished What To Expect Video', { title });
      break;
    default:
      throw new Error('unrecognized analytic event');
  }
};

const onboardingWelcomeVideoStarted = () => {
  analytics.track('Started Onboarding Welcome Video');
};

const clickedClientToDo = (url: string) => {
  analytics.track('Clicked Client ToDo', { url });
};

const educationalMaterialsVideoWatched = (
  type: 'started' | 'finished',
  title: string,
) => {
  switch (type) {
    case 'started':
      analytics.track('Started Educational Materials Video', { title });
      break;
    case 'finished':
      analytics.track('Finished Educational Materials Video', { title });
      break;
    default:
      throw new Error('unrecognized analytic event');
  }
};

const clickedOnVideoForYou = (info: {
  type: 'on-demand' | 'content-library';
  title: string;
}) => {
  const { type, title } = info;
  analytics.track('Video For You Clicked', {
    type,
    title,
  });
};

const clickedDoulaSupportOnBirthHub = () => {
  analytics.track('Clicked Doula Support On Birth Hub');
};

const clickedChecklistOnBirthHub = () => {
  analytics.track('Clicked Checklist On Birth Hub');
};

const checklistItemClicked = (
  item:
    | 'weekly-quiz'
    | 'consult'
    | 'birth-plan'
    | 'mama-class'
    | 'program-overview',
) => {
  analytics.track('Clicked Get Started Checklist Item', { item });
};

const attemptToSignUpWithNonMaePartner = (
  insuranceProvider: string | null,
  state: string,
) => {
  analytics.track('Attempted Sign Up with Non-Mae Partner Insurance', {
    insuranceProvider,
    state,
  });
};

const clickedOnTrainingMaterial = (type: 'video' | 'guide', title: string) => {
  analytics.track('Clicked On Training Material', { type, title });
};

const doulaUpdatedAvailability = () => {
  analytics.track('Doula Updated Availability');
};

const doulaViewedSessionPlanningPage = () => {
  analytics.track('Doula Viewed Session Planning Page');
};

const clickedRequestNextAppointment = () => {
  analytics.track('User Clicked Request Next Appointment');
};

const clickedImNotReadyForNextAppointment = () => {
  analytics.track("User Clicked I'm Not Ready For Next Appointment");
};

const dismissedTimeForNextAppointmentModal = () => {
  analytics.track('User Dismissed Time For Next Appointment Modal');
};

const SegmentEvents = {
  noop,
  page,
  identify,
  logout,
  trackBirthPlanEmailSubmitted,
  startWeeklyQuiz,
  completedWeeklyQuiz,
  clickedOnLifestyleImage,
  lifestyleImageModalDismissed,
  clickedOnRecommendation,
  recommendationModalDismissed,
  userSignUpButtonClicked,
  userSignedUp,
  doulaSignUpButtonClicked,
  expertSearchStateChanged,
  birthPlanCreated,
  birthPlanUpdated,
  birthPlanEditButtonClicked,
  contentLibrarySearched,
  doulaListSearched,
  onboardingStepViewed,
  profileUpdated,
  passwordUpdated,
  emailUpdated,
  sessionRequestedByMember,
  checkInSubmitted,
  sessionScheduledByDoula,
  npsScoreModalOpened,
  npsScoreAnswerSelected,
  npsScoreRatingSelected,
  npsScoreNextButtonPressed,
  npsScoreCancelButtonPressed,
  npsScoreTellUsMoreSubmitButtonPressed,
  npsScoreTellUsMoreCancelButtonPressed,
  npsScoreRatingSubmitted,
  sessionCancelModalOpened,
  sessionCancelModalDismissed,
  sessionCanceled,
  identifyWithUserIdOnly,
  checklistViewed,
  checklistActionCompleted,
  allChecklistCompleted,
  inHouseConsultRequested,
  consultFormCompleted,
  mamaClassSuggestionButtonClicked,
  mamaClassWaitlistButtonClicked,
  mamaClassSignUpButtonClicked,
  mamaClassRegisterButtonClicked,
  mamaClassInterestedButtonClicked,
  previousDoulaSubmitted,
  doulaSwitchedClientOrder,
  shareButtonClicked,
  phoneConsultClicked,
  consultFormClicked,
  alreadyWorkingWithDoulaClicked,
  skipConsultClicked,
  onDemandVideoInteraction,
  mamaClassWatchButtonClicked,
  programOverviewVideoWatched,
  whatToExpectVideoWatched,
  onboardingWelcomeVideoStarted,
  clickedClientToDo,
  educationalMaterialsVideoWatched,
  clickedOnVideoForYou,
  clickedDoulaSupportOnBirthHub,
  clickedChecklistOnBirthHub,
  checklistItemClicked,
  attemptToSignUpWithNonMaePartner,
  clickedOnTrainingMaterial,
  doulaUpdatedAvailability,
  doulaViewedSessionPlanningPage,
  clickedRequestNextAppointment,
  clickedImNotReadyForNextAppointment,
  dismissedTimeForNextAppointmentModal,
};

export default SegmentEvents;
